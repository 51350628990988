import React, {Component} from 'react'
import {Modal, Button, Form, Typography, Space} from 'antd';
const { Link } = Typography;

class taskResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            taskData: {
                files: [],
                demandResult: '',
                address: '',
                locationName: '',
                locationAddr: '',
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({...nextProps});
    }

    componentDidMount() {
        this.props.onRef(this)
    }

    handleOk = () => {
        this.setState({
            visible: false,
        });
    }
    handleCancel = () => {
        this.setState({
            visible: false,
        });
    }
    open = (id) => {
        React.$reqGet(`/employee/payroll-detail-result?detailId=${id}`).then(({data}) => {
            const value = {
                files: [],
                demandResult: '',
                locationName: '',
                locationAddr: '',
            }
            for (let item in data){
                value[item] = data[item]
            }
            value.address = `${value.locationAddr}${value.locationName !== '' && value.locationName? `（${value.locationName}）` : ''}`
            this.setState({taskData: value,  visible: true,})
        })
    }

    render() {
        const style = {
            textAlign: "right",
        };
        const {taskData} = this.state

        const getFile = taskData.files.map( (item,index) =>
            <Link key={index} style={{wordBreak: 'break-all'}} href={React.$openFile(item.filepath)} target="_blank">
                {item.filename}
            </Link>
        )
        return (
            <div>
                <Modal
                    title="查看任务成果" width="580px"
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    footer={<Button onClick={this.handleCancel}>关闭</Button>}>
                    <Form layout="horizontal">
                        {
                            taskData.demandResult ? (
                                <Form.Item label="任务成果：">
                                    {taskData.demandResult}
                                </Form.Item>
                            ) : ''
                        }
                        {
                            taskData.address ? (
                                <Form.Item label="地点定位：">
                                    {taskData.address}
                                </Form.Item>
                            ) : ''
                        }
                        {
                            taskData.files.length > 0 ? (
                                <Form.Item label="验收文件：">
                                    <Space direction="vertical">
                                        {getFile}
                                    </Space>
                                </Form.Item>
                            ) : ''
                        }
                    </Form>
                </Modal>
            </div>
        )
    }
}

export default taskResults
