import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import md5 from 'md5'


import { Layout, Menu, Dropdown, Breadcrumb, Form, Modal, Input, message, Button } from 'antd';
import { SolutionOutlined, DownOutlined, BorderOuterOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import { setToken, getToken } from '@js/storage'

import './main.less';

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

const breadcrumbNameMap = {
    '/supervision/companyCorePersonnel': '董监高',
    '/supervision/cooperativeMerchant': '合作商户',
    '/supervision/cooperativeMerchant/detail': '详情',
    '/supervision/freelance': '自由职业者',
    '/supervision/freelance/detail': '详情',
    '/supervision/invoice': '自由职业者',
    '/supervision/invoice/detail': '详情',
    '/statistics/paystatistics': '数据统计',
    '/register/wxregister': '人员注册',
}

const menuList = [
    {
        title: '工作台',
        pathname: '/uker_tax/home',
        icon: <BorderOuterOutlined />
    },
    {
        title: '众包查询',
        icon: <SolutionOutlined />,
        children: [
            { title: '合作商户', pathname: '/uker_tax/supervision/cooperativeMerchant'},
            { title: '自由职业者', pathname: '/uker_tax/supervision/freelance'},
            { title: '发票记录', pathname: '/uker_tax/supervision/invoice'},
            { title: '董监高', pathname: '/uker_tax/supervision/companyCorePersonnel'},
        ]
    },
    // {
    //     title: '销售统计',
    //     icon: <SolutionOutlined />,
    //     children: [
    //         { title: '数据统计', pathname: '/uker_tax/statistics/paystatistics'},
    //     ]
    // },
    {
        title: '人员注册',
        icon: <SolutionOutlined />,
        children: [
            { title: '人员注册', pathname: '/uker_tax/register/wxregister'},
        ]
    }
]

const ChangePwd = (props) => {
    const pwdFormRef = React.createRef()
    const changePwd = () => {
        pwdFormRef.current.submit()
    }
    const onChangeFinish = ({password}) => {
        React.$reqPost('/sys/password/update', {
            userId: React.$storage.get('userInfo').userId,
            password: md5(password)
        }).then( res => {
            message.success('密码修改成功！');
            props.handleCancel && props.handleCancel(false)
        })
    }
    return (<Modal
        title="修改密码"
        visible={props.visible}
        onOk={changePwd}
        onCancel={props.handleCancel}
      >
          <Form ref={pwdFormRef}
              name="pwdForm" onFinish={onChangeFinish}>
                  <Form.Item name="password" label="密码"
                      rules={[
                          { required: true, },
                          { max: 40, message: '不得超过40个字符'},
                          { min: 6, message: '不得超过6个字符'}
                      ]}>
                      <Input.Password size="large"
                          iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} placeholder="请输入密码" />
                  </Form.Item>
          </Form>
  </Modal>)
}
class Main extends Component {

    state = {
        extraBreadcrumbItems: null,
        openKeys: [],
        selectedKeys: ['1'],
        pwdVisible: false,
        agreementVisible: false,
    }
    componentDidMount() {
        //如果token不存在，退出登录
        if (!getToken()) {
            this.props.history.replace({
                pathname: '/login',
            })
        }
        if(React.$storage.get('userLoginOne') !== 1) {
            this.setState({
                agreementVisible: true
            })
        }
        this.showTestList()
        const pathname = this.props.location.pathname
        this.setMenusSelectedKeys(pathname);
        this.props.history.listen(route => {
            this.setMenusSelectedKeys(route.pathname)
        })

    }
    componentWillUnmount() {
        //在组件销毁的时候将异步方法撤销
        this.setState = () => {
            return
        }
    }
    setMenusSelectedKeys = (pathname) => {
        menuList.find( (item, pIndex) => {
            if (item.children) {
                const index = item.children.findIndex(item => item.pathname === pathname)
                if (index >= 0) {
                    try {
                        this.setState({
                            openKeys: [`sub${pIndex+1}`],
                            selectedKeys: [`${pIndex+1}-${index + 1}`]
                        }, () => {
                            console.log(`[selectedKeys]当前菜单:${this.state.selectedKeys}`)
                        })
                    } catch {}
                    return true
                }
            }
            if (item.pathname === pathname) {
                this.setState({
                    selectedKeys: [`${pIndex+1}`],
                    openKeys: [`sub${pIndex+1}`],
                }, () => {
                    console.log(`[selectedKeys]当前菜单:${this.state.selectedKeys}`)
                })
            }
            return false
        })
    }
    dropdownMenuClick = ({key}) => {
        this[key] && this[key]()
    }
    logout = () => {
        React.$reqPost('/sys/logout').then( res => {
            setToken('')
            this.props.resetSignTime(); //清空缓存时间
            this.props.history.replace({
                pathname: '/login',
            })
        })
    }
    //切换服务商
    dropdownServiceClick = ({key}) => {
        React.$reqPost('/sys/changeChannelMerchant',React.$storage.get('serviceList')[key]).then( ({ data }) => {
            const { token, ...userInfo } = data
            setToken(token)
            React.$storage.set('userInfo', {
                ...userInfo
            })
            this.props.resetSignTime(); //清空缓存时间
            let href = window.location.href
            window.location.href = href


        })
    }
    //字段查看权限
    showTestList = () => {
        React.$reqGet('/sys/taxHiddenItem').then( ({ data }) => {
            React.$storage.set('testList', data.taxHiddenItemList)
        })
    }
    handelPwdModal = () => {
        this.setState({
            pwdVisible: !this.state.pwdVisible
        })
    }
    closeAgreement = () => {
        React.$storage.set('userLoginOne', 1)
        this.setState({
            agreementVisible: false
        })
    }
    getPath = () => {
        const pathSnippets = this.props.location.pathname.split('/').filter(i => i);
        return pathSnippets.map((_, index) => {
            let url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
            return index === 0 ? '' : (
                <Breadcrumb.Item key={url}>
                    <Link to={url}>
                        {breadcrumbNameMap[url]}
                    </Link>
                </Breadcrumb.Item>
            );
        })
    }
    pageTuring = (e, pathname) => {
        this.props.history.push(pathname)
    }
    render() {
        const dropdownMenu = (
            <Menu onClick={this.dropdownMenuClick}>
              <Menu.Item key="logout">退出登录</Menu.Item>
              <Menu.Item key="handelPwdModal">修改密码</Menu.Item>
            </Menu>
        );

        const serviceList = (React.$storage.get('serviceList') || []).map((item, index) => (
            <Menu.Item key={index}>{item.channelMerchantName}</Menu.Item>
        ))
        const dropdownService = (
            <Menu onClick={this.dropdownServiceClick}>
                {serviceList}
            </Menu>
        );
        // let defaultSelectedKeys = '1-1'
        const getMenu = (menu = menuList, pIndex) => {
            if (menu && menu.length > 0) {
                if (!pIndex) {
                    return menu.map( (item, index) => {
                        if (!item.pathname) {
                            return <SubMenu key={`sub${index + 1}`} icon={item.icon} title={item.title}>
                                { getMenu(item.children, index + 1) }
                            </SubMenu>
                        }
                        return <Menu.Item key={`${index + 1}`} icon={item.icon} onClick={(e)=> {this.pageTuring(e, item.pathname)}}>{item.title}</Menu.Item>
                    })
                }
                return menu.map( (item, index) => {
                    return <Menu.Item key={`${pIndex}-${index + 1}`} onClick={(e)=> {this.pageTuring(e, item.pathname)}}>{item.title}</Menu.Item>
                })
            }
        }
        return (
            <Layout>
                <ChangePwd visible={this.state.pwdVisible} handleCancel={this.handelPwdModal}/>
                <Header className="main-header">
                    <div className="logo" style={{paddingRight: '40px', float: 'left'}}>
                        <img src={require("@img/headerLogo.png")} alt="logo"/>
                        <span style={{paddingLeft: '20px'}}>众包查询平台</span>
                    </div>

                    <Dropdown overlay={dropdownService}>
                        <span className="main-hearder-dropdown">
                            服务商：{React.$storage.get('userInfo').channelMerchantName} <DownOutlined />
                        </span>
                    </Dropdown>

                    <Dropdown overlay={dropdownMenu}>
                        <a className="ant-dropdown-link main-hearder-dropdown" style={{float: 'right'}}>
                            {React.$storage.get('userInfo').realName} <DownOutlined />
                        </a>
                    </Dropdown>
                </Header>

                <Layout className="layout-content">
                    <div className="left-sider">
                        <Sider className="main-sider">
                            <Menu mode="inline"
                                selectedKeys={this.state.selectedKeys}
                                defaultOpenKeys={['sub2']}>
                                { getMenu() }
                            </Menu>
                        </Sider>
                    </div>

                    <Layout style={{ marginLeft: 224 }}>
                        {/* <Breadcrumb style={{ marginBottom: '14px' }}>
                            { this.getPath() }
                        </Breadcrumb> */}
                        <Content className="main-content">
                            { this.props.children }
                        </Content>
                    </Layout>
                </Layout>
                <Modal
                    title="会员须知"
                    width={720}
                    closable={false}
                    visible={this.state.agreementVisible}
                    footer={[
                        <Button key="submit" type="primary" onClick={this.closeAgreement}>
                            已悉知
                        </Button>
                    ]}>
                    <div className="agreement">
                        <div className="title">代开发票温馨提示</div>
                        <div>各注册会员∶</div>
                        <div className="p">为防范发票虚开风险，我们在为您提供优质服务的同时，温馨提示如下∶</div>
                        <div className="bold">一、您对本次提供的开票信息真实性、完整性和准确性承担法律责任。</div>
                        <div className="bold">二、虚开发票的定义</div>
                        <div className="p bold">《中华人民共和国发票管理办法》第二十二条规定∶</div>
                        <div className="p">（一）为他人、为自己开具与实际经营业务情况不符的发票;</div>
                        <div className="p">（二）让他人为自己开具与实际经营业务情况不符的发票;</div>
                        <div className="p">（三）介绍他人开具与实际经营业务情况不符的发票。</div>
                        <div className="h2">三、虚开发票的后果</div>
                        <div className="p">《中华人民共和国刑法》相关规定∶</div>
                        <div className="p"><span className="bold">第二百零五条 【虚开增值税专用发票、用于骗取出口退税、抵扣税款发票罪】</span>虚开增值税专用发票或者虚开用于骗取出口退税、抵扣税款的其他发票的，处三年以下有期徒刑或者拘役，并处二万元以上二十万元以下罚金;虚开的税款数额较大或者有其他严重情节的，处三年以上十年以下有期徒刑，并处五万元以上五十万元以下罚金;虚开的税款数额巨大或者有其他特别严重情节的，处十年以上有期徒刑或者无期徒刑，并处五万元以上五十万元以下罚金或者没收财产。</div>
                        <div className="p">单位犯本条规定之罪的，对单位判处罚金，并对其直接负责的主管人员和其他直接责任人员，处三年以下有期徒刑或者拘役;虚开的税款数额较大或者有其他严重情节的，处三年以上十年以下有期徒刑;虚开的税款数额巨大或者有其他特别严重情节的，处十年以上有期徒刑或者无期徒刑。</div>
                        <div className="p">虚开增值税专用发票或者虚开用于骗取出口退税、抵扣税款的其他发票，是指有为他人虚开、为自己虚开、让他人为自己虚开、介绍他人虚开行为之一的。</div>
                        <div className="p"><span className="bold">第二百零五条之一【虚开发票罪】</span>虚开本法第二百零五条规定以外的其他发票，情节严重的，处二年以下有期徒刑、拘役或者管制，并处罚金;情节特别严重的，处二年以上七年以下有期徒刑，并处罚金。</div>
                        <div className="p">单位犯前款罪的，对单位判处罚金，并对其直接负责的主管人员和其他直接责任人员，依照前款的规定处罚。</div>
                        <div className="p bold">本人已阅读《代开发票温馨提示》，知悉相关法律规定，愿意承担相关法律责任。</div>
                    </div>
                </Modal>

            </Layout>
        )
    }
}
const mapStateToProps = ({ signTime }) => {
    return {
        // signTime
    }
}
const mapDispatchToProps = dispatch => {
    return {
        resetSignTime: signTime => {
            dispatch({ signTime: '', type: 'changeTime' })
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Main))
