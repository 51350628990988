const set = (name, data) => {
	// name += APP_NAME;
	if (!(data instanceof Object) && !(data instanceof Array)) {
		window.localStorage.setItem(name, data);
	} else {
		window.localStorage.setItem(name, JSON.stringify(data));
	}
}

const get = (name) => {
	// name += APP_NAME;
	const value = window.localStorage.getItem(name) || '';
	try {
		console.log(JSON.parse(value))
		return JSON.parse(value) || '';
	} catch(e) {
		return value;
	}
}

const remove = (name) => {
	// name += APP_NAME;
	window.localStorage.removeItem(name);
}

export const storage = {
	set,
	get,
	remove,

}

export const getToken = () => {
	return get('Token')
}
export const setToken = (authorization) => {
	set('Token', authorization)
}
