
//0.未认证、1.认证中、2.已认证、3.已拒绝
export const authenticationStatusList = [
    {value: 0, label: '未认证'},
    {value: 1, label: '认证中'},
    {value: 2, label: '已认证'},
    {value: 3, label: '已拒绝'}
]

//0:身份证,1:港澳居民来往内地通行证,2:回乡证,3:护照,4:其他证件
export const credentialsTypeList = [
    {value: 0, label: '身份证'},
    {value: 1, label: '港澳居民来往内地通行证'},
    {value: 2, label: '回乡证'},
    {value: 3, label: '护照'},
    {value: 4, label: '其他证件'}
]
//开票状态0：未开票，1：已开票
export const invoiceStatusList = [
    {value: 0, label: '未开票'},
    {value: 1, label: '已开票'}
]

//支付方式：0-连连，1-网商，2-微信 4-平安 5-招行 20-线下发放
export const payTypeList = [
    {value: 0, label: '连连'},
    {value: 1, label: '网商'},
    {value: 2, label: '微信'},
    {value: 4, label: '平安'},
    {value: 5, label: '招行'},
    {value: 6, label: '线上支付（XH）'},
    {value: 7, label: '支付宝'},
    {value: 20, label: '线下发放'},
]
