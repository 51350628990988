import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Modal, Button} from 'antd';
import Detail from './detail'
import MyTable from '@/components/MyTable'

class InvoiceGroup extends Component {
    searchFormRef = React.createRef();
    constructor(props){
        super(props);
        this.state = {
        }
    }
    state = {
        visible: false,

        selectedRowKeys: [],

        //详情弹框相关数据
        detailVisible: false,
        detailSearchData: { id: '' },


    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.visible) { //弹窗显示就初始化数据
            this.setState({...nextProps},() => {
                this.getList()
            })
        }
    }
    getList = () => {
        setTimeout(() => {

            this.child.getList(this.props.searchData)
        })
    }
    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    }
    //查看详情
    showDetail = (record) => {
        this.setState({
            detailVisible: true,
            detailSearchData: {id: record.id}
        });
    }
    //详情关闭
    onDetailClose = () => {
        this.setState({ detailVisible: false });
    }
    //点击发票组，弹出发票组列表
    showInvoice = (record) => {
        this.setState({ invoiceGroupVisible: true });
    }
    //发票组关闭
    onInvoiceClose = () => {
        this.setState({ invoiceGroupVisible: false });
    }
    //表格数据获取成功后赋值
    getListSuccess = (data) => {

    }
    handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.onClose();
    }
    onMyTableRef = (ref) => {
        this.child = ref
    }
    render() {
        const columns = [
            { title: '序号', key: 'index', width: 80, render:(text,record,index)=> `${index+1}`,align: 'center', },
            { title: '操作', render: (text,record,index)=> (
                <a onClick={(e) => {this.showDetail(record)}}>查看</a>
            ), width: 80, align: 'center'},
            { title: '完税组编号', dataIndex: 'groupOrderNo', key: 'groupOrderNo', width: 260, align: 'center',},
            { title: '完税月份', dataIndex: 'finishDate', key: 'finishDate', align: 'center', },
            { title: '创建日期', dataIndex: 'createTime', key: 'createTime', align: 'center', filter: (text) => (
                text ? React.$moment(text).format('YYYY-MM-DD') : ''
            )},
        ];

        return (
            <div>
                <Modal
                    title="完税组列表" width="1200px" className="detail-model"
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    footer={<Button onClick={this.handleCancel}>取消</Button>}
                    >
                    <MyTable
                        onRef={this.onMyTableRef}
                        title="完税组列表"
                        rowKey="id"
                        url={this.props.listUrl}
                        columns={columns}
                        exportType="select"
                        callback={this.getListSuccess}></MyTable>
                </Modal>

                <Detail
                    visible={this.state.detailVisible}
                    url={this.props.detailUrl}
                    searchData={this.state.detailSearchData}
                    onClose={this.onDetailClose}></Detail>
            </div>
        )
    }
}

export default connect()(withRouter(InvoiceGroup))
