import React, { Component } from 'react'
import { Modal, Button } from 'antd';

class ImgModal extends Component {
    constructor(props){
        super(props);
        const { visible } = props
        this.state = {
            visible,
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.visible) { //弹窗显示就初始化数据
            this.setState({...nextProps},() => {
                // this.getDetail(nextProps.tabActiveKey)
            })
        }
    }
    handleOk = () => {
        this.setState({
            visible: false,
        });
    }
    handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.onClose();
    }
    render() {
        const { url } = this.props
        const urlAry = url.split(',')
        const getImg = urlAry.map( (item,index) => <img src={React.$viewImg(item)} key={index} style={{maxWidth: '100%', marginBottom: '10px'}} alt=""/>)
        return (
            <div>
                <Modal
                    title="查看图片" width="1100px"
                    visible={this.props.visible}
                    onCancel={this.handleCancel}
                    footer={<Button onClick={this.handleCancel}>关闭</Button>}
                    >
                        <div style={{textAlign: 'center'}}>
                        {getImg}
                        </div>
                </Modal>
            </div>
        )
    }
}

export default ImgModal