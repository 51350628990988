import React, { Component } from "react";
import { Form, Input, Button, DatePicker } from "antd";
import MyTable from "@/components/MyTable";
import ImgModal from "@/components/imgModal";
import { authenticationStatusList, credentialsTypeList } from "@js/constant";
// const { Option } = Select;
const { RangePicker } = DatePicker;

class Tab2Content extends Component {
	searchFormRef = React.createRef();
	constructor(props) {
		super(props);
		this.state = {
			searchData: {},
			imgVisible: false,
			imgUrl: "",
		};
	}
	componentDidMount() {
		this.props.onRef(this);
	}
	onSearchFinish = (values) => {
		const { signTime, ...otherValues } = values;
		this.child.getList({
			...otherValues,
			...this.state.searchData,
			startTime: signTime ? signTime[0] : "",
			endTime: signTime ? signTime[1] : "",
		});
	};
	onSearchReset = () => {
		this.resetSearchForm();
		this.child.getList(this.state.searchData);
	};
	resetSearchForm = () => {
		this.searchFormRef.current.resetFields();
	};
	getList = (values) => {
		this.setState({ searchData: values });
		this.child.getList(values);
	};
	//表格数据获取成功后赋值
	getListSuccess = (data) => {
		this.props.callback(data);
	};
	onMyTableRef = (ref) => {
		this.child = ref;
	};
	//显示自由职业者详情
	showFreelanceDetail = (record) => {
		this.props.showFreelanceDetail(record, "1");
	};
	onImgModalClose = () => {
		this.setState({ imgVisible: false });
	};
	render() {
		const getOpt1 = (text, record, index) => {
			if (record.agreementUrl) {
				return (
					<span>
						<a href={React.$openFile(record.agreementUrl)} target="_blank" rel="noopener noreferrer">
							查看协议
						</a>
						&nbsp;&nbsp;
					</span>
				);
			}
			return "";
		};
		const getOpt2 = (text, record, index) => {
			if (record.employeeId) {
				return (
					<a
						onClick={(e) => {
							this.showFreelanceDetail(record);
						}}>
						查看人员
					</a>
				);
			}
			return "";
		};

		const columns = [
			{ title: "序号", key: "index", width: 80, render: (text, record, index) => `${index + 1}`, align: "center" },
			{
				title: "操作",
				render: (text, record, index) => (
					<span>
						{getOpt1(text, record, index)}
						{getOpt2(text, record, index)}
					</span>
				),
				width: 200,
				align: "center",
			},
			{ title: "姓名", dataIndex: "employeeName", key: "employeeName", align: "center" },
			{
				title: "证件类型",
				dataIndex: "credentialsType",
				key: "credentialsType",
				width: 150,
				align: "center",
				filter(text, render) {
					return React.$getLabel(credentialsTypeList, parseInt(text));
				},
			},
			{ title: "证件号码", dataIndex: "credentialsNo", key: "credentialsNo", width: 200, align: "center" },
			{
				title: "认证状态",
				dataIndex: "authenticationStatus",
				key: "authenticationStatus",
				align: "center",
				filter: (text, render) => '已认证'// React.$getLabel(authenticationStatusList, parseInt(text)),
			},
			{ title: "协议编号", dataIndex: "agreementCode", key: "agreementCode", width: 200, align: "center" },
			{ title: "协议名称", dataIndex: "agreementName", key: "agreementName", align: "center" },
			{ title: "签约地点", dataIndex: "locationAddr", key: "locationAddr", align: "center" },
			// { title: '协议类型', dataIndex: 'agreementType',key: 'agreementType', align: 'center', filter: (text, render)=> (
			//     parseInt(text) === 1 ? '三方协议' : '双方协议'
			// )},
			{
				title: "签约时间",
				dataIndex: "signTime",
				key: "signTime",
				filter: (text) => (text ? React.$moment(text).format("YYYY-MM-DD") : ""),
				sorter: true,
				width: 200,
				align: "center",
			},
		];

		return (
			<div>
				<Form
					className="search-form"
					ref={this.searchFormRef}
					name="searchForm2"
					layout="inline"
					onFinish={this.onSearchFinish}>
					<Form.Item name="signTime" label="签约日期：">
						<RangePicker allowClear />
					</Form.Item>
					<Form.Item name="agreementName" label="协议名称：">
						<Input placeholder="请输入" rules={[{ max: 40, message: "不得超过40个字符" }]} allowClear />
					</Form.Item>
					{/* <Form.Item name="agreementType" label="协议类型：" >
                        <Select placeholder="请选择" style={{ width: 174 }} allowClear>
                            <Option value={0}>双方协议</Option>
                            <Option value={1}>三方协议</Option>
                        </Select>
                    </Form.Item> */}
					<Form.Item name="employeeName" label="姓名：">
						<Input placeholder="请输入" allowClear autoComplete="off" />
					</Form.Item>
					<Form.Item name="credentialsNo" label="证件号码：">
						<Input placeholder="请输入" allowClear autoComplete="off" />
					</Form.Item>
					<Form.Item>
						<Button htmlType="submit" type="primary">
							查询
						</Button>
						&nbsp;
						<Button htmlType="button" onClick={this.onSearchReset}>
							重置
						</Button>
					</Form.Item>
				</Form>

				<MyTable
					title="人员协议"
					onRef={this.onMyTableRef}
					rowKey="id"
					url="/coreCustomer/userAgreementList"
					columns={columns}
					y={500}
					callback={this.getListSuccess}></MyTable>
				<ImgModal visible={this.state.imgVisible} url={this.state.imgUrl} onClose={this.onImgModalClose}></ImgModal>
			</div>
		);
	}
}

export default Tab2Content;
