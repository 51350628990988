import React, { Component } from 'react'
import { Button, message, Modal, Alert, Pagination, InputNumber } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import ExcelUtil from '@js/util/excelUtil';

// function formatNumber(value) {
//     value += '';
//     const list = value.split('.');
//     const prefix = list[0].charAt(0) === '-' ? '-' : '';
//     let num = prefix ? list[0].slice(1) : list[0];
//     let result = '';
//     while (num.length > 3) {
//       result = `,${num.slice(-3)}${result}`;
//       num = num.slice(0, num.length - 3);
//     }
//     if (num) {
//       result = num + result;
//     }
//     return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
//   }

class NumericInput extends Component {
    onChange = e => {
        // const { value } = e.target;
        // const reg = /^-?\d*(\.\d*)?$/;
        // if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
        // if (value > 1000) {
        //     return
        // }
        // if (value <= 9) {
        //     return
        // }
        // this.props.onChange(value);
        // }
        const value = e;
        this.props.onChange(value)
    };

    // '.' at the end or only '-' in the input box.
    // onBlur = () => {
    //     const { value, onBlur, onChange } = this.props;
    //     let valueTemp = value;
    //     if (value.charAt(value.length - 1) === '.' || value === '-') {
    //     valueTemp = value.slice(0, -1);
    //     }
    //     onChange(valueTemp.replace(/0*(\d+)/, '$1'));
    //     if (onBlur) {
    //     onBlur();
    //     }
    // };

    render() {
        // const { value } = this.props;
        // const title = value ? (
        // <span className="numeric-input-title">{value !== '-' ? formatNumber(value) : '-'}</span>
        // ) : (
        // 'Input a number'
        // );
        return (
            <InputNumber min={10} max={1000} precision={0} maxLength={4} {...this.props} onChange={this.onChange} />
        );
    }
}

class MyTable extends Component {
    messageClose = true
    constructor(props){
        super(props);
        // const { exportType } = props
        this.state = {
            visible: false,
            highExportVisible: false,
            highExportNum: 0,
            pageSize: 1000,
            currentPage: 1,
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({...nextProps});
    }
    componentDidMount () {
        // this.props.onRef(this)
    }
    showExportModal = () => {
        if (this.props.exportType !== 'select') {
            this.setState({visible: true}); return;
        }
        this.selectDataExport()
    }

    cancel = () => {
        this.setState({
            visible: false,
            highExportVisible: false,
            highExportNum: 0,
            pageSize: 1000,
            page: 1,
        })
    }
    //导出
    export = (exportRows) => {
        let columns = this.props.columns.concat()
        const index = this.props.columns.findIndex(item => item.title === '操作')
        if(index !== -1) {
            columns.splice(index, 1)
        }
        ExcelUtil.exportExcel(columns, exportRows, `${this.props.fileName || '记录表'}.xlsx`)
        this.setState({visible: false})
        this.setState({highExportVisible: false})
    }
    //导出选中的数据
    selectDataExport = () => {
        if (this.props.exportRows && this.props.exportRows.length > 0) {
            this.export(this.props.exportRows)
            return
        }
        if (this.messageClose) {
            this.messageClose = false
            message.warn("请选择至少一条记录", 3, () => {
                //关闭回调
                this.messageClose = true
            })
        }
    }
    //高级导出
    highExport = () => {
        React.$reqPost(this.props.url, {
            pageNo: this.state.page,
            pageSize: this.state.pageSize,
            ...this.props.searchData
        }).then(({data}) => {
            if (data.records.length > 0) {
                this.export(data.records)
                return
            }
        })
    }
    showHighExport = () => {
        this.setState({highExportVisible: true})
    }
    pageChange = (page, pageSize) => {

        this.setState({
            page,
            highExportNum: pageSize
        })
    }
    onNumberChange = (pageSize) => {
        this.setState({ page: 1, pageSize });
    }
    render() {
        let footerBtn = null, exportContent = null

        //footer按钮
        if(this.state.highExportVisible) {
            const { pageSize } = this.state, { total } = this.props
            const highExportNum = this.state.highExportNum
                                || (total >= pageSize ? pageSize : total)
            footerBtn = <Button type="primary" icon={<DownloadOutlined />} onClick={this.highExport} >本次导出{highExportNum}条</Button>
        } else {
            footerBtn = <Button onClick={this.cancel}>取消</Button>
        }

        //model内容
        if(this.state.highExportVisible) {
            exportContent = <div>
                <Alert message='每次最少导出 10 条， 最多导出 1000 条， 每次导出条数可在下方 "每次导出条数" 修改。"'
                    type="info" showIcon
                    style={{marginBottom: 20}} />
                <Pagination
                    total={this.props.total}
                    showTotal={(total, range) => `共${total}条`}
                    pageSize={this.state.pageSize}
                    defaultCurrent={1}
                    showSizeChanger={false}
                    onChange={this.pageChange}
                    style={{marginBottom: 20}}
                />
                每次导出条数：<NumericInput style={{ width: 120 }} value={this.state.pageSize} onChange={this.onNumberChange} />
            </div>
        } else {
            exportContent = <div>
                <Button type="primary" icon={<DownloadOutlined />} onClick={this.showHighExport}>
                高级导出
                </Button>&nbsp;&nbsp;&nbsp;
                <Button type="primary" icon={<DownloadOutlined />} onClick={this.selectDataExport}>
                导出表格所选数据
                </Button>
            </div>
        }

        return (
            <div>
                <Button type="primary" icon={<DownloadOutlined />} onClick={this.showExportModal}>
                    导出
                </Button>

                <Modal title="导出"
                    visible={this.state.visible} onCancel={this.cancel}
                    footer={footerBtn}>
                    {exportContent}
                </Modal>
            </div>
        )
    }
}

export default MyTable
