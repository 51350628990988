import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {Button, Form, Input, DatePicker, Tabs} from 'antd';
import MyTable from '@/components/MyTable'

const {TabPane} = Tabs;

class CooperativeMerchant extends Component {
    searchFormRef = React.createRef();
    state = {
        pageTitle: '数据统计',
        selectedRowKeys: [],
        year: React.$moment().format('YYYY'),
        tabActiveKey: '1',
        titleList: []
    }
    componentDidMount() {
        this.pageRefresh()
    }

    componentWillUnmount() {
        //在组件销毁的时候将异步方法撤销
        this.setState = () => {
            return
        }
    }
    yearChange = (date, dateString) => {
        this.setState({year: dateString}, () => {});
    }
    setTabActiveKey = (key) => {
        this.setState({tabActiveKey: key}, () => {
            this.pageRefresh()
        });
    }
    pageRefresh = () => {
        const query = this.searchFormRef.current.getFieldsValue()
        const postData = {
            ...query,
            year: this.state.year,
            type: parseInt(this.state.tabActiveKey)
        }
        const list = []
        const max = postData.type === 1 ? 12 : 4
        for(let i = 0 ; i < max ; i++) {
            const obj  = {
                key: `amount${i + 1}`,
                dataIndex: `amount${i + 1}`,
                title: i + 1,
                width: 120, align: 'center'
            }
            obj.title = max === 4 ? `${postData.year.substr(2,3)}年${obj.title}季度` : `${postData.year.substr(2,3)}年${obj.title}月`
            list.push(obj)
        }
        this.setState({titleList: list})

        this.child.getList(postData)
    }
    onSearchFinish = values => {
        this.pageRefresh()
    }
    onSearchReset = () => {
        this.searchFormRef.current.resetFields()
        this.pageRefresh()
    }
    onSelectChange = selectedRowKeys => {
        this.setState({selectedRowKeys});
    }

    //表格数据获取成功后赋值
    getListSuccess = (data) => {
    }
    onMyTableRef = (ref) => {
        this.child = ref
    }

    render() {

        const {selectedRowKeys} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };


        const columns = [
            {title: '序号', key: 'index', width: 80, render: (text, record, index) => `${index + 1}`, align: 'center',},
            {title: '人员姓名', dataIndex: 'employeeName', key: 'employeeName', width: 150, align: 'center'},
            {title: '证件号码', dataIndex: 'documentNumber', key: 'documentNumber', width: 120, align: 'center'},
            ...this.state.titleList
        ];

        return (
            <div className="test">
                <p>{this.state.pageTitle}</p>
                <Form className="search-form" ref={this.searchFormRef} name="searchForm" layout="inline"
                      initialValues={{employeeName: '',documentNumber: '',year: React.$moment()}}
                      onFinish={this.onSearchFinish}>
                    <Form.Item name="employeeName" label="人员姓名：">
                        <Input placeholder="请输入" allowClear autoComplete="off"/>
                    </Form.Item>
                    <Form.Item name="documentNumber" label="证件号码：">
                        <Input placeholder="请输入" allowClear autoComplete="off"/>
                    </Form.Item>
                    <Form.Item name="year" label="统计年份：">
                        <DatePicker style={{width: '196px'}} picker="year" allowClear={false} onChange={this.yearChange}/>
                    </Form.Item>

                    <Form.Item>
                        <Button htmlType="submit" type="primary">查询</Button>&nbsp;
                        <Button htmlType="button" onClick={this.onSearchReset}>重置</Button>
                    </Form.Item>
                </Form>
                <Tabs defaultActiveKey={this.state.tabActiveKey} onChange={this.setTabActiveKey}>
                    <TabPane tab="按月统计" key="1"/>
                    <TabPane tab="按季度统计" key="2"/>
                </Tabs>
                <MyTable
                    title={this.state.pageTitle}
                    onRef={this.onMyTableRef}
                    rowSelection={rowSelection}
                    rowKey="documentNumber"
                    url="/employee/pay/month/list"
                    columns={columns}
                    callback={this.getListSuccess}></MyTable>
            </div>
        )
    }
}

const mapStateToProps = ({signTime}) => {
    return {
        signTime
    }
}

export default connect(
    mapStateToProps,
)(withRouter(CooperativeMerchant))
