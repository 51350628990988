import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Form, Input, Button, message, Typography  } from 'antd';
import md5 from 'md5'
import { setToken } from '@js/storage'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import './index.less';
const { Title } = Typography;

// const tabInfo = ['税务监管平台']; //'手机登录', '账号登录'

class Login extends Component {
    formRef = React.createRef();
    state = {
        tabIndex: 0
    }
    componentDidMount() {
        this.props.resetSignTime()
    }
    componentWillUnmount() {
        //在组件销毁的时候将异步方法撤销
        this.setState = () => {
            return
        }
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
        //     if (!err) {
        //         console.log('Received values of form: ', values);
        //         // if(PatchUser(values)){
        //         //     this.setState({
        //         //         isLoding: true,
        //         //     });

        //         //     setCookie('mspa_user',JSON.stringify(values));
        //         //     message.success('login successed!'); //成功信息
        //         //     let that = this;
        //         //     setTimeout(function() { //延迟进入
        //         //         that.props.history.push({pathname:'/app',state:values});
        //         //     }, 2000);

        //         // }else{
        //         //     message.error('login failed!'); //失败信息
        //         // }
        //     }
        });
    };
    loginFormSubmit = () => {
        this.formRef.submit();
    }
    onFinish = (value) => {
        const { password, ...otherData } = value
        const postData = Object.assign({}, {
            password: md5(password),
            ...otherData
        })
        React.$reqPost('/sys/login', postData).then(({data}) => {
            React.$storage.set('serviceList', data)
            const { token, ...userInfo } = data[0]
            setToken(token)
            message.info('登录成功');
            React.$storage.set('userLoginOne', 0)
            React.$storage.set('testList', [])
            React.$storage.set('userInfo', {
                ...userInfo
            })
            this.props.history.replace({
                pathname: '/uker_tax/home'
            })
        })
    }
    tabChange = (index, e) => {
        e.preventDefault();
        this.setState({tabIndex: index})
    }
    render() {
        /*** 手机登录 ***/
        // let loginForm1 = <Form ref={this.formRef} name="loginForm" onFinish={this.onFinish}
        //     initialValues={{ remember: true, }}>
        //     <Form.Item name="username"
        //         rules={[
        //             { required: true, message: 'Please input your Username!', },
        //         ]}>
        //         <Input size="large" placeholder="请输入手机号码" />
        //     </Form.Item>
        //     <Form.Item name="password"
        //         rules={[
        //         { required: true, message: 'Please input your Password!', },
        //         ]}
        //     >
        //         <Input size="large" addonAfter=".com" placeholder="请输入验证码" />
        //     </Form.Item>
        // </Form>

        /*** 账号登录 ***/
        let loginForm2 = <Form ref={this.formRef} name="loginForm" onFinish={this.onFinish}
            initialValues={{ remember: true }} >
            <Form.Item name="userName"
                rules={[
                    { required: true, message: '请输入账号/手机号码', },
                    { max: 40, message: '不得超过40个字符'},
                ]}>
                <Input size="large" placeholder="请输入账号/手机号码" />
            </Form.Item>
            <Form.Item name="password"
                rules={[
                    { required: true, message: '请输入密码' },
                    { max: 40, message: '不得超过40个字符'},
                ]}>
                <Input.Password size="large"
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} placeholder="请输入密码" />
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" type="primary" size="large" block>登录</Button>
            </Form.Item>
        </Form>
        return (
            <div className="login">
                <div className="login-image">
                    <img src={require("@img/login-image.png")} alt="login"/>
                </div>
                <div className="login-form">
                    {/* <ul className="login-tab">
                        {tabInfo.map((item, index) => {
                            let className = this.state.tabIndex === index ? 'active' : ''
                            return <li key={index} onClick={(e) => this.tabChange(index, e)} className={[className]}>{item}</li>
                        })}
                    </ul> */}
                    <Title className="login-title">悠客众包查询平台</Title>
                    { loginForm2 }
                </div>
            </div>
        )
    }
}
const mapStateToProps = ({ signTime }) => {
    return {
        // signTime
    }
}
const mapDispatchToProps = dispatch => {
    return {
        resetSignTime: signTime => {
            dispatch({ signTime: '', type: 'changeTime' })
        }
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login)
