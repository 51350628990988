import React, { Component } from "react";
import { Form, Input, Button, Select, DatePicker } from "antd";
import MyTable from "@/components/MyTable";
import TaskResults from "@/components/TaskResults";
import { payTypeList } from "@js/constant";
import ReceiptModel from '@/components/ReceiptModel'

const { Option } = Select;
const { RangePicker } = DatePicker;

class Tab2Content extends Component {
	searchFormRef2 = React.createRef();

	constructor(props) {
		super(props);
		this.state = {
			searchData: {},
		};
	}

	componentDidMount() {
		this.props.onRef(this);
	}

	onSearchFinish = (values) => {
		const { signTime, ...otherValues } = values;
		this.child.getList({
			...otherValues,
			taskStartTimeStart: signTime ? signTime[0] : "",
			taskStartTimeEnd: signTime ? signTime[1] : "",
			employeeId: this.state.searchData.employeeId,
		});
	};
	onSearchReset = () => {
		this.resetSearchForm();
		this.child.getList({ employeeId: this.state.searchData.employeeId });
	};
	resetSearchForm = () => {
		this.searchFormRef2.current.resetFields();
	};
	getList = (values) => {
		this.setState({ searchData: values });
		this.child.getList(values);
	};
	//表格数据获取成功后赋值
	getListSuccess = (data) => {
		this.props.callback(data);
	};
	onMyTableRef = (ref) => {
		this.child = ref;
	};
	onTaskResultsRef = (ref) => {
		this.taskResultsRef = ref;
	};
	getLegnth(str){
        let arr = [];
        if(typeof str == 'string'){
            arr = str.split(',');
        }
        return arr.length;
    }
	//查看任务成果
	showTaskResults = (record) => {
		this.taskResultsRef.open(record.id)
	};
	//查看发票组
	showInvoice = (record) => {
		this.props.showInvoice(record);
	};
    //服务确认单
    showAcceptance= (record) => {
		this.props.showAcceptance(record);
	};
	showTaskDetail = (record) => {
		this.props.showTaskDetail(record);
	};

	render() {
		const block = {display: 'block'}
		const getOpt = (text, record) => {
			let html = '';
			if (record.voucherPath) {
				html = (
					<ReceiptModel voucherPath={record.voucherPath}/>
				);
			}
			return html;
		};
		const getOpt2 = (text, record, index) => {
			return (<a key={index} onClick={() => {this.showTaskResults(record);}} style={block}>任务成果</a>);
		};
		const columns = [
			{ title: "序号", key: "index", width: 80, render: (text, record, index) => `${index + 1}`, align: "center" },
			{
				title: "操作",
				render: (text, record, index) => (
					<>
						{getOpt(text, record, index)}
						{getOpt2(text, record, index)}
					</>
				),
				width: 100,
				align: "center",
			},
			{ title: "任务编号", dataIndex: "code", key: "code", width: 160, align: "center" },
			{
				title: "任务名称",
				dataIndex: "taskName",
				key: "taskName",
				align: "center",
				render: (text, record) => (
					<span>
						<a
							onClick={() => {
								this.showTaskDetail(record);
							}}>
							{text}
						</a>
						&nbsp;&nbsp;
					</span>
				),
			},
			{ title: "任务商户", dataIndex: "customerName", key: "customerName", width: 230, align: "center" },
			{
				title: "完成状态",
				dataIndex: "uploadStatus",
				key: "uploadStatus",
				filter: (text, render) => "已完成",
				align: "center",
			},
			{ title: "姓名", dataIndex: "employeeName", key: "employeeName", width: 200, align: "center" },
			{ title: "证件号码", dataIndex: "documentNumber", key: "documentNumber", width: 200, align: "center" },
			{ title: "手机号码", dataIndex: "mobile", key: "mobile", width: 200, align: "center" },
			// {
			// 	title: "发单时间",
			// 	dataIndex: "taskStartTime",
			// 	key: "taskStartTime",
			// 	filter: (text) => (text ? React.$moment(text).format("YYYY-MM-DD") : ""),
			// 	align: "center",
			// },
			// {
			// 	title: "接单时间",
			// 	dataIndex: "taskOrderTime",
			// 	key: "taskOrderTime",
			// 	filter: (text) => (text ? React.$moment(text).format("YYYY-MM-DD") : ""),
			// 	align: "center",
			// },
			// {
			// 	title: "完成时间",
			// 	dataIndex: "taskFinishTime",
			// 	key: "taskFinishTime",
			// 	filter: (text) => (text ? React.$moment(text).format("YYYY-MM-DD") : ""),
			// 	align: "center",
			// },
			{
				title: "完成月份",
				dataIndex: "demandMonth",
				key: "demandMonth",
				filter: (text) => (text ? React.$moment(text).format("YYYY-MM") : ""),
				align: "center",
			},
			{
				title: "结算日期",
				dataIndex: "validTime",
				key: "validTime",
				filter: (text) => (text ? React.$moment(text).format("YYYY-MM-DD") : ""),
				align: "center",
			},
			{
				title: "发放状态",
				dataIndex: "payrollStatus",
				key: "payrollStatus",
				filter: (text, render) => "发放成功",
				align: "center",
			},
			// {
			// 	title: "完税状态",
			// 	dataIndex: "billStatus",
			// 	key: "billStatus",
			// 	width: 200,
			// 	align: "center",
			// 	filter: (text, record) => (record.invoiceGroupOrderNo ? "已完税" : "未完税"),
			// },
			{
				title: "发票组数量",
				dataIndex: "invoiceGroupOrderNo",
				key: "invoiceGroupOrderNo",
				render: (text, record, index) => (
                    this.getLegnth(text) !== 0 ? <a onClick={(e) => {this.showInvoice(record)}}>{this.getLegnth(text)}</a> : '0'
				),
				align: "center",
				ellipsis: true,
			},
			{
				title: "发放金额",
				dataIndex: "infactPayAmount",
				key: "infactPayAmount",
				sorter: true,
				width: 200,
				align: "center",
			},
			{ title: "银行卡号", dataIndex: "bankCard", key: "bankCard", width: 200, align: "center" },
			{
				title: "支付方式",
				dataIndex: "payType",
				key: "payType",
				align: "center",
				filter: (text, record) => React.$getLabel(payTypeList, parseInt(text)),
			},
            {
				title: "服务确认单",
				dataIndex: "acceptanceGroupIds",
				key: "acceptanceGroupIds",
				render: (text, record, index) => (
                    this.getLegnth(text) !== 0 ? <a onClick={(e) => {this.showAcceptance(record)}}>{this.getLegnth(text)}</a> : '0'
				),
				align: "center",
				ellipsis: true,
			},
		];
		if (!React.$storage.get('testList').some(item => item === '0')) {
			columns.splice(2, 0,{title: '项目名称', dataIndex: 'remark', key: 'remark', width: 150, align: 'center'})
		}
		return (
			<div>
				<Form
					className="search-form"
					ref={this.searchFormRef2}
					name="searchForm2"
					layout="inline"
					onFinish={this.onSearchFinish}>
					{/*<Form.Item name="signTime" label="发单时间：">*/}
					{/*	<RangePicker allowClear />*/}
					{/*</Form.Item>*/}
					<Form.Item name="customerName" label="所属商户：" rules={[{ max: 40, message: "不得超过40个字符" }]}>
						<Input placeholder="请输入" allowClear autoComplete="off" />
					</Form.Item>
					<Form.Item name="employeeName" label="姓名：" rules={[{ max: 40, message: "不得超过40个字符" }]}>
						<Input placeholder="请输入" allowClear autoComplete="off" />
					</Form.Item>
					{
						!React.$storage.get('testList').some(item => item === '0') ? (
							<Form.Item name="remark" label="项目名称：" rules={[{max: 40, message: "不得超过40个字符"}]}>
								<Input placeholder="请输入" allowClear autoComplete="off"/>
							</Form.Item>
						) : ''
					}
					<Form.Item name="documentNumber" label="证件号码：" rules={[{ max: 40, message: "不得超过40个字符" }]}>
						<Input placeholder="请输入" allowClear autoComplete="off" />
					</Form.Item>
					{/* <Form.Item name="billStatus" label="完税状态：">
						<Select placeholder="请选择" style={{ width: 174 }} allowClear>
							<Option value={0}>未完税</Option>
							<Option value={1}>已完税</Option>
						</Select>
					</Form.Item> */}
					{/* <Form.Item name="uploadStatus" label="完成状态：">
						<Select placeholder="请选择" style={{ width: 174 }} allowClear>
							<Option value={"0"}>未完成</Option>
							<Option value={"1"}>已完成</Option>
						</Select>
					</Form.Item> */}

					<Form.Item>
						<Button htmlType="submit" type="primary">
							查询
						</Button>
						&nbsp;
						<Button htmlType="button" onClick={this.onSearchReset}>
							重置
						</Button>
					</Form.Item>
				</Form>

				<MyTable
					onRef={this.onMyTableRef}
					title="任务列表"
					rowKey="id"
					url="/employee/task/list"
					columns={columns}
					y={500}
					callback={this.getListSuccess}></MyTable>
				<TaskResults onRef={this.onTaskResultsRef}></TaskResults>
			</div>
		);
	}
}

export default Tab2Content;
