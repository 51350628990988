import React, { Component } from 'react'
import { Table } from 'antd';

import Export from '@/components/Export'

class MyTable extends Component {
    listSearchData = {}
    sorter = {//默认排序
        // orderByField: 'updateTime',
        // order: 'desc'
    }
    constructor(props){
        super(props);
        const { hidePagination, rowSelection } = this.props
        this.state = {
            dataSource: [],
            selectedRows: [],
            rowSelection: rowSelection || [] ,
            pagination: !hidePagination ? {
                current: 1,
                pageSize: 10,
                showQuickJumper: true,
                showTotal: (total, range) => `共${total}条`
                // showSizeChanger: true,
                // pageSizeOptions: [30, 60, 90, 120],
            } : false,
        }
    }
    // UNSAFE_componentWillReceiveProps(nextProps) {
    //     this.setState({...nextProps});
    // }
    componentDidMount () {
        this.props.onRef(this)
    }
    getList = (values, page = {pageNo: 1, pageSize: 10}) => {
        this.listSearchData = values ? {...values} : {}

        //无翻页请求
        if (!this.state.pagination) {
            React.$reqPost(this.props.url, this.listSearchData).then(({data}) => {
                this.setState({
                    dataSource: data,
                    selectedRows: [],
                    rowSelection: {
                        selectedRowKeys: []
                    }
                })

                this.props.callback(data);
            })
            return
        }

        const postData = Object.assign({...page}, this.listSearchData, this.sorter)

        React.$reqPost(this.props.url, postData).then(({data}) => {
            let pagination = { ...this.state.pagination }
                pagination.current = data.current
                pagination.total = data.total
                pagination.pageSize = data.size
            this.setState({
                pagination,
                dataSource: data.records,
                selectedRows: [],
                rowSelection: {
                    selectedRowKeys: []
                }
            })

            this.props.callback(data);
        })
    }

    handlePageChange = (page, pageSize) => {

    }
    onSelectChange = (selectedRowKeys, rows) => {
        this.setState({
            selectedRows: rows,
            rowSelection: { selectedRowKeys }
        })
        if (this.props.rowSelection) {
            this.props.rowSelection.onChange && this.props.rowSelection.onChange(selectedRowKeys, rows)
        }
    }
    handleChange = (pagination, filters, sorter) => {
        //排序
        const { order, field } = sorter
        let { current, pageSize } = pagination
        const {sort, orderByField} = this.sorter
        let afterSort; //变化后的排序
        if (order) {
            afterSort = order === 'ascend' ? 'asc' : 'desc'
        }
        //判断排序是否变化，排序变化页码变为1
        if (afterSort !== sort || field !== orderByField) {
            current = 1
        }

        if (!order) {
            this.sorter = {}
        } else {
            //默认updateTime降序, 'asc'上升 'desc'下降
            this.sorter = {
                sort: order === 'ascend' ? 'asc' : 'desc',
                orderByField: field
            }
        }

        this.getList(this.listSearchData, {pageNo: current, pageSize: pageSize })
    }
    render() {

        const { columns } = this.props;
        const rowSelection = {
            selectedRowKeys: this.state.rowSelection.selectedRowKeys,
            onChange: this.onSelectChange,
        };

        let scrollX = 0
        scrollX += 62 //多选框时，其占62px，30width+32margin
        for(let i = 0; i < columns.length; i++){
            const column = columns[i]
            columns[i].width = column.width || 120 //未设置默认120
            scrollX += parseInt(column.width || 120)

            if (column.filter && !column.render) {
                column.render = (text, record, index) => (
                    column.filter(text, record, index)
                )
            }
        }

        // const getExportDom = () => {
        //     if(this.state.dataSource.length > 0 && (this.props.hidePagination || this.state.pagination.current === 1)) {
        //         return <Export
        //             fileName={this.props.title}
        //             url={this.props.url}
        //             columns={columns}
        //             searchData={{
        //                 ...this.listSearchData,
        //                 ...this.sorter
        //             }}
        //             total={this.state.pagination.total}
        //             dataSource={this.state.dataSource}
        //             exportRows={this.state.selectedRows}
        //             exportType={this.props.exportType} />
        //     }
        //     return ''
        // }

        return (
            <div>
                <div className="table-optBtn">
                    {/*{ getExportDom() }*/}

                    <Table
                        rowSelection={rowSelection}
                        dataSource={this.state.dataSource}
                        rowKey={this.props.rowKey}
                        columns={columns}
                        scroll={{ x: scrollX, y: this.props.y || 600 }}
                        pagination={this.state.pagination}
                        onChange={this.handleChange} />
                </div>
            </div>

        )
    }
}

export default MyTable
