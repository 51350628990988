
let URL = ''
// console.log(process.env.NODE_ENV)
// switch(process.env.NODE_ENV) {
//     case 'production':
//         URL = 'https://47.111.104.87'; break; //正式
//     case 'developmentTest':
//         URL = 'https://47.114.39.3'; break; //QA测试
//     default:
//         URL = 'https://47.111.108.57'; break; //开发测试
// }
const BaseLink = '/uker-tax-supervise'
export default {
    URL,
    BaseLink
}
