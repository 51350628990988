import React, { Component } from "react";
import { Modal, Button } from "antd";
import { FileImageOutlined } from '@ant-design/icons';
class ReceiptModel extends Component {

    constructor(props) {
        super(props)
        const { voucherPath } = props;
        this.state = {
            pathList: voucherPath ? voucherPath.split(',') : [],
            visible: false,
        };
    }

    showModal = () => {
        this.setState({
            visible: true,
        })
    }
    closeModal = () => {
        this.setState({
            visible: false,
        })
    }

    render() {
        return (
            <>
                <Modal
                    title="回单详情" width="580px"
                    visible={this.state.visible}
                    onCancel={this.closeModal}
                    footer={<Button onClick={this.closeModal}>关闭</Button>}
                >
                    {
                        this.state.pathList.map((item, index) => {
                            return <div key={index}>
                                <span style={{paddingRight:'5px'}}>{index + 1}.</span>
                                <FileImageOutlined style={{ paddingRight:'5px', color: '#ffa910' }} />
                                <a href={React.$openFile(item)} target="_blank" rel="noopener noreferrer">
                                    回单{index + 1}
                                </a>
                            </div>
                        })
                    }
                </Modal>

                <a style={this.props.style} onClick={this.showModal}>
                    查看回单
                </a>
            </>
        )
    }
}

export default ReceiptModel;
