import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
class CooperativeMerchant extends Component {
    searchFormRef = React.createRef();
    state = {
        pageTitle: '小程序注册',
    }
    render() {
        return (
            <div className="wx-register">
                <img src={require("@img/ukerw-psp-medicine-prod.jpg")} alt=""/>
                <div className="text">
                    <p>注册会员：</p>
                    <p>1. 使用微信【扫一扫】扫描上方小程序；</p>
                    <p>2. 进入认证签约页面，完成人员的认证与协议签署；</p>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ signTime }) => {
    return {
        signTime
    }
}

export default connect(
    mapStateToProps,
)(withRouter(CooperativeMerchant))
