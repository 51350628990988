import XLSX from 'xlsx';

function importExcel(file){
    // 获取上传的文件对象
    const { files } = file.target;
    // 通过FileReader对象读取文件
    const fileReader = new FileReader();
    fileReader.onload = event => {
        try {
            const { result } = event.target;
            // 以二进制流方式读取得到整份excel表格对象
            const workbook = XLSX.read(result, { type: 'binary' });
            let data = []; // 存储获取到的数据
            // 遍历每张工作表进行读取（这里默认只读取第一张表）
            for (const sheet in workbook.Sheets) {
                if (workbook.Sheets.hasOwnProperty(sheet)) {
                    // 利用 sheet_to_json 方法将 excel 转成 json 数据
                    data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                    // break; // 如果只取第一张表，就取消注释这行
                }
            }
            console.log(data);
        } catch (e) {
            // 这里可以抛出文件类型错误不正确的相关提示
            console.log('文件类型不正确');
            return;
        }
    };
    // 以二进制方式打开文件
    fileReader.readAsBinaryString(files[0]);

}

function exportExcel(headers, data, fileName = '记录表.xlsx') {
    let outputCols = [], _headers = {}, _data = {}

    for(let i = 0; i < headers.length; i++ ) {
        const headerItem = headers[i]
        const position = String.fromCharCode(65 + i) + 1
        outputCols.push({wpx: headerItem.width || 120 })
        _headers[position] = { key: headerItem.key, v: headerItem.title }

        for(let j = 0; j < data.length; j++) { 
            const dataItem = data[j]
            const dataPosition = String.fromCharCode(65 + i) + (j + 2)
            let content = dataItem[headerItem.key]
            if(typeof content !== 'number' && !content) content = ''
            if(headerItem.key === 'index') content = j + 1 //序号
            if(headerItem.filter) {
                content = headerItem.filter(dataItem[headerItem.key], dataItem, i)
            }

            _data[dataPosition] = {v: content }
        }
    }

    
    // 合并 headers 和 data
    const output = Object.assign({}, _headers, _data);
    // 获取所有单元格的位置
    const outputPos = Object.keys(output);
    // 计算出范围 ,["A1",..., "H2"]
    const ref = `${outputPos[0]}:${outputPos[outputPos.length - 1]}`;

    // // 构建 workbook 对象
    const wb = {
        SheetNames: ['mySheet'],
        Sheets: {
            mySheet: Object.assign(
                {},
                output,
                {
                    '!ref': ref,
                    '!cols': outputCols,
                },
            ),
        },
    };

    // // 导出 Excel
    XLSX.writeFile(wb, fileName);
}

export default { importExcel, exportExcel };
