import React, { Component } from "react";
import { Modal, Tabs, Descriptions, Button, Row, Col } from "antd";
import Tab2Content from "./tabs/tab2";
import Tab3Content from "./tabs/tab3";
import Tab4Content from "./tabs/tab4";

import { authenticationStatusList, credentialsTypeList } from "@js/constant";

class Detail extends Component {
	isFirstReq = [0, 0, 0, 0];
	otherSearchData = ["", "", "", ""];
	constructor(props) {
		super(props);
		const { visible, searchData, tabActiveKey } = props;
		this.state = {
			visible,
			searchData,
			tabActiveKey,

			baseInfo: {},
			taskVisible: false,
			taskData: {},
		};
	}
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.visible && this.state.visible !== nextProps.visible) {
			//弹窗显示就初始化数据
			this.setState({ ...nextProps }, () => {
				this.getDetail(nextProps.tabActiveKey);
			});
		}
	}
	getDetail = (activeKey) => {
		const { searchData } = this.state;
		const { employeeId, groupIds, ...otherData } = searchData;

		switch (activeKey) {
			case "1": //基础信息
				this.getBaseInfo();
				break;
			case "2": //人员协议
				let tab2Interval = setInterval(() => {
					if (this.tab2TableChild) {
						this.tab2TableChild.getList(
							this.otherSearchData[1] || {
								...otherData,
								employeeId,
							}
						);
						clearInterval(tab2Interval);
					}
				}, 20);
				break;
			case "3": //发单记录
				let tab3Interval = setInterval(() => {
					if (this.tab3TableChild) {
						this.tab3TableChild.getList(
							this.otherSearchData[2] || {
								...otherData,
								employeeId,
							}
						);
						clearInterval(tab3Interval);
					}
				}, 20);
				break;
			case "4": //接单明细
				let tab4Interval = setInterval(() => {
					if (this.tab4TableChild) {
						this.tab4TableChild.getList({
							groupIds,
						});
						clearInterval(tab4Interval);
					}
				}, 20);
				break;
			default:
				break;
		}
	};
	tabChange = (activeKey) => {
		//如果是隐藏状态
		if (!this.state.visible) return;

		this.setState({ tabActiveKey: activeKey }, () => {
			//如果不是第一次,就不请求
			if (this.isFirstReq[parseInt(activeKey) - 1] > 0) return;
			this.getDetail(activeKey);
		});
	};
	getBaseInfo = () => {
		React.$reqPost(`/employee/detail/${this.state.searchData.employeeId}`).then(({ data }) => {
			this.isFirstReq[0]++;
			this.setState({ baseInfo: data });
		});
	};
	handleCancel = () => {
		this.setState({
			visible: false,
		});
		this.isFirstReq = [0, 0, 0, 0];

		//清空子组件查询表单
		if (this.tab2TableChild) this.tab2TableChild.resetSearchForm();
		if (this.tab3TableChild) this.tab3TableChild.resetSearchForm();
		if (this.tab4TableChild) this.tab4TableChild.resetSearchForm();

		this.props.onClose();
	};
	goTab2 = (record) => {
		this.otherSearchData[1] = {
			listDateTimeStart: React.$moment(record.taxMonth).format("YYYY-MM-DD"),
			listDateTimeEnd: React.$moment(record.taxMonth).endOf("month").format("YYYY-MM-DD"),
			employeeId: record.employeeId,
		};
		this.setState({ tabActiveKey: "2" }, () => {
			this.getDetail("2");
		});
	};
	goTab3 = (record) => {
		this.otherSearchData[2] = {
			listDateTimeStart: React.$moment(record.month).format("YYYY-MM-DD"),
			listDateTimeEnd: React.$moment(record.month).endOf("month").format("YYYY-MM-DD"),
			employeeId: record.employeeId,
		};
		this.setState({ tabActiveKey: "3" }, () => {
			this.getDetail("3");
		});
	};

	//tab2表格数据获取成功
	getTab2ListSuccess = (data) => {
		this.isFirstReq[1]++;
		this.otherSearchData[1] = "";
	};

	//tab2表格数据获取成功
	getTab3ListSuccess = (data) => {
		this.isFirstReq[2]++;
		this.otherSearchData[2] = "";
	};

	//tab2表格数据获取成功
	getTab4ListSuccess = (data) => {
		this.isFirstReq[3]++;
	};
	showInvoice = (record) => {
		this.props.showInvoice(record);
	};
    //服务确认单
    showAcceptance = (record) => {

		this.props.showAcceptance(record);
	};
	statusFilter = (status) => {
		switch (status) {
			case 1:
				return "待发布";
			case 2:
				return "进行中";
			case 3:
				return "已结束";
			case 4:
				return "已取消";
			default:
				return "";
		}
	};

	priceRuleFilter = (rule) => {
		switch (rule) {
			case 1:
				return "按次数收费";
			case 2:
				return "按小时收费";
			case 3:
				return "按实际收费";
			case 4:
				return "按单价收费";
			default:
				return "";
		}
	};

	// 任务详情
	showTaskDetail = (record) => {
		this.setState({
			taskVisible: true,
		});
		const url = `/busDemand/detail.do?demandId=${record.demandId}`;
		React.$reqGet(url).then(({ data }) => {
			if (data) {
				this.setState({ taskData: data });
			} else {
				this.setState({ taskData: { msg: "暂无数据" } });
			}
		});
	};
	render() {
		const SigingInfo = () => {
			return (
				<Descriptions title="协议信息" column={1}>
					<Descriptions.Item label="协议名称">{this.state.baseInfo.agreementName}</Descriptions.Item>
					<Descriptions.Item label="协议类型">
						{parseInt(this.state.baseInfo.contractType) === 1 ? "三方协议" : "双方协议"}
					</Descriptions.Item>
					<Descriptions.Item label="签约时间">{this.state.baseInfo.signingTime}</Descriptions.Item>
					<Descriptions.Item label="协议内容">
						{this.state.baseInfo.agreementUrl ? (
							<a
								href={React.$openFile(this.state.baseInfo.agreementUrl)}
								className="ant-btn"
								target="_blank"
								rel="noopener noreferrer">
								查看协议
							</a>
						) : (
							""
						)}
					</Descriptions.Item>
				</Descriptions>
			);
		};

		const NoInfo = () => {
			return (
				<Descriptions title="协议信息">
					<Descriptions.Item>此人尚未签约！</Descriptions.Item>
				</Descriptions>
			);
		};

		const showInfo = () => {
			const list = [
				{label: '任务名称', value: 'name'},
				{label: '任务状态', value: 'demandStatus'},
				{label: '任务类别', value: 'categoryName'},
				{label: '计价规则', value: 'priceRule'},
				{label: '任务期限', value: 'time'},
				{label: '任务发起时间', value: 'createTime'},
				{label: '任务周期', value: 'missionCycle'},
				{label: '任务区域', value: 'demandAreas'},
				{label: '任务内容', value: 'content'},
				{label: '承揽人要求', value: 'employeeRequirement'},
			]
			const filer = {
				demandStatus: (val) => { return this.statusFilter(val)},
				priceRule: (val) => { return this.priceRuleFilter(val)},
				getValue: (val, key) => { return filer[key] !== undefined ? filer[key](val) : val}
			}
			const taskData = this.state.taskData
			taskData.time = '一个月'
			const fun = ({label, value}) => {
				return taskData[value] ? (<Row>
					<Col span={5} style={style}>
						{label}：
					</Col>
					<Col span={18}>{filer.getValue(taskData[value], value)}</Col>
				</Row>) : ''
			}
			return (
				list.map((x, index) =>
					fun(x)
				)
			)
		};

		const { baseInfo, taskData } = this.state;
		const style = {
			textAlign: "right",
		};
		return (
			<div>
				<Modal
					title="自由职业者详情"
					width="1200px"
					className="detail-model"
					visible={this.props.visible}
					onCancel={this.handleCancel}
					footer={<Button onClick={this.handleCancel}>取消</Button>}>
					<Tabs activeKey={this.state.tabActiveKey} onChange={this.tabChange}>
						<Tabs.TabPane tab="基础信息" key="1">
							<Descriptions title={`自由职业者（ID:${baseInfo.documentNumber || ""})`} column={4}>
								<Descriptions.Item label="姓名">{baseInfo.userName}</Descriptions.Item>
								<Descriptions.Item label="证件类型">
									{React.$getLabel(credentialsTypeList, parseInt(baseInfo.documentType))}
								</Descriptions.Item>
								<Descriptions.Item label="证件号码">{baseInfo.documentNumber}</Descriptions.Item>
								<Descriptions.Item label="认证状态">
									已认证
									{baseInfo.authenticationTime === undefined ? '' : `（于${baseInfo.authenticationTime}）`}
								</Descriptions.Item>
								<Descriptions.Item label="联系电话">{baseInfo.mobile}</Descriptions.Item>
								<Descriptions.Item label="银行卡号">{baseInfo.bankCard}</Descriptions.Item>
								{/* <Descriptions.Item label="开户行">{baseInfo.openBank}</Descriptions.Item> */}
								<Descriptions.Item label="居住地址" span={2}>
									{baseInfo.address}
								</Descriptions.Item>
                                {baseInfo.idNumberFrontImage ||  baseInfo.idNumberBackImage? (
                                    <>
                                    <Descriptions.Item label="证件正面" span={2}>
                                        <img src={React.$viewImg(baseInfo.idNumberFrontImage)} style={{ width: "80%" }} alt="" />
                                    </Descriptions.Item>
                                    <Descriptions.Item label="证件反面" span={2}>
                                        <img src={React.$viewImg(baseInfo.idNumberBackImage)} style={{ width: "80%" }} alt="" />
                                    </Descriptions.Item>
                                    </>
                                ) : (
                                    <Descriptions.Item label="证件照片" span={2}>
                                        <img src={React.$viewImg(baseInfo.idNumberImage)} style={{ width: "80%" }} alt="" />
                                    </Descriptions.Item>
                                )}

								{/*{baseInfo.identityVideoUrl && baseInfo.videoPicture === 1 ? (*/}
								{/*		<>*/}
								{/*			<Descriptions.Item label="视频" span={2}>*/}
								{/*				<img src={React.$viewImg(baseInfo.identityVideoUrl)} style={{ width: '100%'}} />*/}
								{/*			</Descriptions.Item>*/}
								{/*		</>*/}
								{/*) : '' }*/}
								{/*{baseInfo.identityVideoUrl && baseInfo.videoPicture !== 1 ? (*/}
								{/*	<>*/}
								{/*		<Descriptions.Item label="视频" span={2}>*/}
								{/*			<video*/}
								{/*				controls*/}
								{/*				width="100%"*/}
								{/*				height="500px">*/}
								{/*				<source src={React.$viewImg(baseInfo.identityVideoUrl)}*/}
								{/*						type="video/ogg"/>*/}
								{/*				<source src={React.$viewImg(baseInfo.identityVideoUrl)}*/}
								{/*						type="video/mp4"/>*/}
								{/*				<source*/}
								{/*					src={React.$viewImg(baseInfo.identityVideoUrl)}*/}
								{/*					type="video/webm"/>*/}
								{/*				<object*/}
								{/*					data={React.$viewImg(baseInfo.identityVideoUrl)}*/}
								{/*					type="video/webm" width="100%" height="500px"/>*/}
								{/*				<embed*/}
								{/*					data={React.$viewImg(baseInfo.identityVideoUrl)}*/}
								{/*					type="video/webm" width="100%" height="500px"/>*/}
								{/*				您的浏览器不支持视频播放，请升级至最新版本*/}
								{/*			</video>*/}
								{/*		</Descriptions.Item>*/}
								{/*	</>*/}
								{/*) : ''}*/}
							</Descriptions>
							{/* signingStatus === 5有数据 其他显示 此人尚未签约！*/}
							{parseInt(baseInfo.signingStatus) === 5 ? SigingInfo() : NoInfo()}
						</Tabs.TabPane>
						<Tabs.TabPane tab="任务列表" key="2">
							<Tab2Content
								onRef={(ref) => {
									this.tab2TableChild = ref;
								}}
								callback={this.getTab2ListSuccess}
								showTaskDetail={this.showTaskDetail}
								showInvoice={this.showInvoice}
                                showAcceptance={this.showAcceptance}></Tab2Content>
						</Tabs.TabPane>
						{/*<Tabs.TabPane tab="纳税信息" key="3">*/}
						{/*	<Tab3Content*/}
						{/*		onRef={(ref) => {*/}
						{/*			this.tab3TableChild = ref;*/}
						{/*		}}*/}
						{/*		callback={this.getTab3ListSuccess}*/}
						{/*		showInvoice={this.showInvoice}*/}
						{/*		goTab2={this.goTab2}></Tab3Content>*/}
						{/*</Tabs.TabPane>*/}
						<Tabs.TabPane tab="完税信息" key="4">
							<Tab4Content
								onRef={(ref) => {
									this.tab4TableChild = ref;
								}}
								callback={this.getTab4ListSuccess}
								showInvoice={this.showInvoice}
								goTab3={this.goTab3}></Tab4Content>
						</Tabs.TabPane>
					</Tabs>
				</Modal>

				<Modal
					title="任务详情"
					width="600px"
					visible={this.state.taskVisible}
					onCancel={() => this.setState({ taskVisible: false })}
					footer={<Button onClick={() => this.setState({ taskVisible: false })}>关闭</Button>}>
					{!taskData.msg ? (
						<>
                        {showInfo()}
						</>
					) : (
						<div style={{ textAlign: "center" }}>{taskData.msg}</div>
					)}
				</Modal>
			</div>
		);
	}
}

export default Detail;
