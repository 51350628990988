import React, { Component } from "react";
import { Form, Input, Button, DatePicker } from "antd";
import MyTable from "@/components/MyTable";

const { RangePicker } = DatePicker;

class Tab3Content extends Component {
	searchFormRef = React.createRef();

	constructor(props) {
		super(props);
		this.state = {
			searchData: {},
		};
	}

	componentDidMount() {
		this.props.onRef(this);
	}

	onSearchFinish = (values) => {
		const { signTime, ...otherValues } = values;
		this.child.getList({
			...otherValues,
			...this.state.searchData,
			startTime: signTime ? signTime[0] : "",
			endTime: signTime ? signTime[1] : "",
		});
	};
	onSearchReset = () => {
		this.resetSearchForm();
		this.child.getList(this.state.searchData);
	};
	resetSearchForm = () => {
		this.searchFormRef.current.resetFields();
	};
	getList = (values) => {
		const { customerId } = values;
		this.setState({ searchData: { customerId } });
		this.child.getList(values);
	};
	//表格数据获取成功后赋值
	getListSuccess = (data) => {
		this.props.callback(data);
	};
	onMyTableRef = (ref) => {
		this.child = ref;
	};
	showAgreement = (record) => {
		this.props.goTab4(record);
	};
	showTaskDetail = (record) => {
		this.props.showTaskDetail(record);
	};
	showPeople = () => {};

	render() {
		const columns = [
			{ title: "序号", key: "index", width: 80, render: (text, record, index) => `${index + 1}`, align: "center" },
			{
				title: "操作",
				render: (text, record) => (
					<span>
						<a
							onClick={(e) => {
								this.showAgreement(record);
							}}>
							接单明细
						</a>
						&nbsp;&nbsp;
					</span>
				),
				width: 100,
				align: "center",
			},
			{ title: "任务编号", dataIndex: "code", key: "code", width: 160, align: "center" },
			{
				title: "任务名称",
				dataIndex: "taskName",
				key: "taskName",
				align: "center",
				render: (text, record) => (
					<span>
						<a
							onClick={() => {
								this.showTaskDetail(record);
							}}>
							{text}
						</a>
						&nbsp;&nbsp;
					</span>
				),
			},
					// {
					//     title: '任务类型',
					//     dataIndex: 'taskType',
					//     key: 'taskType',
					//     width: 200,
					//     align: 'center',
					//     filter: (text, record) => (
					//         text ? (record.parentTaskType ? `${record.parentTaskType} - ${record.taskType}` : record.taskType) : ''
					//     )
					// },
					// {title: '任务内容', dataIndex: 'taskContent', key: 'taskContent', width: 200, align: 'center'},
					// {title: '任务区域', dataIndex: 'area', key: 'area', width: 100, align: 'center'},
					// {
					//     title: '任务状态',
					//     dataIndex: 'taskStatus',
					//     key: 'taskStatus',
					//     width: 200,
					//     align: 'center',
					//     filter: (text, record) => (
					//         parseInt(text) === 0 ? '未发布' : '已发布'
					//     )
					// },
					// {
					//     title: '计价规则',
					//     dataIndex: 'priceRule',
					//     key: 'priceRule',
					//     width: 150,
					//     align: 'center',
					//     filter: (text, record) => (
					//         text ? parseInt(text) === 1 ? '按次数收费' : (parseInt(text) === 2 ? '按小时收费' : (parseInt(text) === 3 ? '按实际收费' : '按单价收费')) : ''
					//     )
					// },
			// {
			// 	title: "申请时间",
			// 	dataIndex: "applyTime",
			// 	key: "applyTime",
			// 	filter: (text) => (text ? React.$moment(text).format("YYYY-MM-DD") : ""),
			// 	sorter: true,
			// 	width: 180,
			// 	align: "center",
			// },
			// {
			// 	title: "审核时间",
			// 	dataIndex: "checkTime",
			// 	key: "checkTime",
			// 	filter: (text) => (text ? React.$moment(text).format("YYYY-MM-DD") : ""),
			// 	sorter: true,
			// 	width: 180,
			// 	align: "center",
			// },
				{ title: "接单人数", dataIndex: "userNum", key: "userNum", sorter: true, align: "center" },
			// {
			// 	title: "平台发单时间",
			// 	dataIndex: "publishTime",
			// 	key: "publishTime",
			// 	filter: (text) => (text ? React.$moment(text).format("YYYY-MM-DD") : ""),
			// 	sorter: true,
			// 	width: 180,
			// 	align: "center",
			// },
					];
		if (!React.$storage.get('testList').some(item => item === '0')) {
			columns.splice(2, 0,{title: '项目名称', dataIndex: 'remark', key: 'remark', width: 150, align: 'center'})
		}
					return (
					<div>
					<Form
					className="search-form"
					ref={this.searchFormRef}
					name="searchForm3"
					layout="inline"
					onFinish={this.onSearchFinish}>
					{/*<Form.Item name="signTime" label="发单日期：">*/}
					{/*<RangePicker allowClear />*/}
					{/*</Form.Item>*/}
					<Form.Item name="taskName" label="任务名称：" rules={[{ max: 40, message: "不得超过40个字符" }]}>
					<Input placeholder="请输入" allowClear autoComplete="off" />
					</Form.Item>
						{
							!React.$storage.get('testList').some(item => item === '0') ? (
								<Form.Item name="remark" label="项目名称：" rules={[{max: 40, message: "不得超过40个字符"}]}>
									<Input placeholder="请输入" allowClear autoComplete="off"/>
								</Form.Item>
							) : ''
						}
					<Form.Item>
						<Button htmlType="submit" type="primary">
							查询
						</Button>
						&nbsp;
						<Button htmlType="button" onClick={this.onSearchReset}>
							重置
						</Button>
					</Form.Item>
				</Form>

				<MyTable
					title="表单记录"
					onRef={this.onMyTableRef}
					rowKey="id"
					url="/coreCustomer/taskList"
					columns={columns}
					y={500}
					callback={this.getListSuccess}></MyTable>
			</div>
		);
	}
}

export default Tab3Content;
